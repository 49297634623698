.display-message-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.display-message {
    width: 80%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.display-message.error {
    background: #ffeded 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #de0303;
}

.display-message.message {
    background: #e3f8ea 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #106e1f;
}
