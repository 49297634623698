.professional-input {
    width: 180px;
    height: 120px;
    border: dotted 2px #ccc;
}

.professional-input-pic {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.professional-pic {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.professional-input-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.professional-uploadpic {
    width: 35px;
    object-fit: content;
}

.professional-input-delete {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.professional-form-photo {
    display: flex;
    flex-wrap: wrap;
}

.professional-photo-box {
    padding: 10px;
}

.professional-addmore-skills {
    text-decoration: underline;
    cursor: pointer;
    padding: 10px;
}

.professional-array-box {
    // display: flex;
    width: 100%;
}

.professional-array-items {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.professional-array-bbox {
    position: relative;
    display: flex;
    align-items: center;
}

.professional-array-item {
    width: 50%;
    padding: 10px;
}

.professional-array-delete {
    position: absolute;
    right: -30px;
    top: 50px;
    width: 20px;
}

.professional-delete-button {
    width: 20px;
    height: 20px;
    background: #ff0000;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.professional-form-input {
    padding: 10px;
}

.professional-form-2 {
    display: flex;
}

.professional-form-2-left {
    width: 70%;
}

.professional-form-2-right {
    width: 30%;
}

.professional-array-cef-left {
    width: 70%;
    padding: 10px;
}

.professional-array-cef-right {
    width: 30%;
    padding: 10px;
}

.professional-upload-box {
    padding: 20px 0;
}

.professional-agree-box {
    display: flex;
    padding: 20px 0;
}

.professional-delivery-items {
    display: flex;
}

.professional-delivery-items-left {
    width: 40%;
    display: flex;
    align-items: center;
    padding: 30px 30px 30px 0;
}

.professional-delivery-items-right {
    width: 40%;
    display: flex;
    align-items: center;
    padding: 30px 0px 30px 30px;
}

.professional-delivery-item {
    width: 50%;
}

.professional-button-box {
    padding: 30px 0;
}

.professional-button {
    width: 30%;
    height: 50px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

@media (max-width: 768px) {
    .professional-array-delete {
        position: absolute;
        right: 30px;
        top: 10px;
        width: 20px;
    }
    .professional-delivery-items {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .professional-delivery-items-left {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .professional-delivery-items-right {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .professional-delivery-item {
        width: 100%;
    }
    .professional-button-box {
        text-align: center;
    }

    .professional-button {
        width: 300px;
    }

    .professional-input {
        width: 160px;
        height: 100px;
    }
}
