.profile-container {
    padding: 30px 0;
    width: 100%;
    display: flex;
}
.profile-box {
    width: 50%;
}
.profile-edit {
    width: 50%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
}
.profile-item {
    padding: 10px 0;
    width: 100%;
    display: flex;
}

.profile-item-name {
    width: 35%;
}

.profile-item-value {
    width: 60%;
}

.profile-edit-box {
}

.profile-edit-span {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
