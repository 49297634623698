@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arimo', sans-serif;
}

button {
    transition: all 0.3s ease-in-out;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}

input {
}

@import './globalVariable.scss';

@import './loading.scss';
@import './homepage.scss';
@import './footer.scss';
@import './header.scss';
@import './layout.scss';
@import './displayMessage.scss';
@import './login.scss';
@import './input.scss';
@import './welcome.scss';
@import './professional.scss';
@import './requestDesign.scss';
@import './profile.scss';
