.header-box {
    display: flex;
    padding: 0px 10px;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.header-items {
    padding-left: 50px;
    cursor: pointer;
}

.header-word-box {
    width: 100%;
    display: flex;
}

.footer-box {
    display: flex;
    padding: 30px 10px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.footer-items {
    padding-left: 50px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .header-items {
        padding-left: 10px;
        font-size: 12px;
    }

    .header-word-box {
        justify-content: flex-end;
    }

    .footer-items {
        padding-left: 10px;
        font-size: 12px;
    }
}
