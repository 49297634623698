.welcome-title {
    text-align: center;
    font: normal normal normal 40px/45px Lobster;
    letter-spacing: 0px;
    color: #fddc3a;
    padding: 30px 0 20px 0;
}

.welcome-detail {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
}

.welcome-left {
    width: 40%;
    text-align: right;
}

.welcome-pic {
    width: 70%;
    object-fit: content;
}

.welcome-right {
    width: 55%;
}

.resend-email-button {
    width: 200px;
    height: 40px;
    background: #edc400 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.welcome-items {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
}

.welcome-item {
    width: 40%;
    display: flex;
    padding: 20px;
}

.welcome-item-icon {
    color: #edc400;
}

.welcome-item-title {
    margin-left: 20px;
    font-size: 14px;
}

.welcome-button-area {
    padding: 30px 0;
}

.welcome-button {
    width: 50%;
    height: 50px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.checkout-number {
    // background: #edc400 0% 0% no-repeat padding-box;
    background: #b4b2b2;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.checkout-number.click {
    cursor: pointer;
    color: black;
}

.welcome-header-box {
    display: flex;
    align-items: center;
}
.welcome-header-items {
    display: flex;
    align-items: center;
}

.welcome-header-title {
    margin: 0 10px;
}
.welcome-header-symbol {
    margin-right: 10px;
}

.welcome-personal-box {
    width: 50%;
}

.welcome-inside-title {
    font-size: 20px;
    padding: 20px 0;
}

.personal-form-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.personal-form-left {
    width: 60%;
}

.personal-form-right {
    width: 30%;
}

.drag-drop-input {
    background: #dfe1e2;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drag-drop-input-pic {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drag-drop-pic {
    width: 130px;
    height: 130px;
    object-fit: content;
    border-radius: 50%;
}

.drag-drop-input-delete {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 20px;
    height: 20px;

    background: red;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.personal-button {
    padding: 30px 0;
}

.welcome-security-box {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.welcome-security-left {
    display: flex;
    align-items: center;
}

.welcome-security-verified {
    width: 200px;
    height: 50px;
    background: #fff9d9 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome-security-button-box {
    padding-top: 50px;
}
@media (max-width: 768px) {
    .welcome-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .welcome-title {
        font: normal normal normal 30px/35px Lobster;
    }

    .welcome-left {
        width: 90%;
        text-align: center;
    }

    .welcome-pic {
        width: 70%;
        object-fit: content;
    }

    .welcome-right {
        width: 80%;
        padding: 30px 0;
    }

    .welcome-items {
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0;
    }

    .welcome-item {
        width: 100%;
        display: flex;
        padding: 20px;
    }

    .welcome-button-area {
        text-align: center;
    }

    .welcome-personal-box {
        width: 100%;
    }

    .checkout-number {
        // background: #edc400 0% 0% no-repeat padding-box;
        background: #b4b2b2;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 12px;
    }

    .welcome-header-title {
        font-size: 12px;
    }

    .personal-form-box {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .personal-form-left {
        width: 100%;
    }

    .personal-form-right {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .personal-button {
        text-align: center;
    }

    .welcome-security-button-box {
        text-align: center;
    }
}
