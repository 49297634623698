.request-design-userinfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.request-design-userinfo-item {
    display: flex;
    padding: 10px 0;
    width: 50%;
}

.request-design-userinfo-left {
    width: 200px;
    text-align: right;
    padding-right: 10px;
}

.request-design-room {
    display: flex;
    justify-content: space-between;
}

.request-design-room-item {
    width: 30%;
}

.request-design-kitchen-images-box {
    display: flex;
    justify-content: space-between;
}

.request-design-kitchen-size-pic {
    width: 100%;
    object-fit: contain;
}

.request-design-kitchen-images-left {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.request-design-kitchen-images-right {
    width: 50%;
}
