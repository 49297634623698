.main__page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main__body {
    flex: 1;
    // margin-top: 10px;
    margin-bottom: 20px;
    // display: flex;
    // width: 100%;
    // height: 100%;
    // justify-content: center;
    // align-items: flex-start;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    z-index: 100;
    background: rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .main__body {
        margin-top: 10px;
    }
}
