.login-box {
    display: flex;
    justify-content: space-between;
}

.login-box-item-left {
    width: 50%;
    display: flex;
    // justify-content: center;
}

.login-box-img-box {
    width: 100%;
    padding: 30px 0;
    text-align: center;
}

.login-box-item {
    width: 50%;
    display: flex;
    justify-content: center;
}

.login-pic {
    width: 80%;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
}

.login-button {
    width: 60%;
    height: 50px;
    background: #fddc3a 0% 0% no-repeat padding-box;
    border-radius: 36px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        box-shadow: 0px 3px 10px rgba(97, 105, 109, 0.5);
    }
}

.login-box-right {
    width: 60%;
}

.login-remember {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #707070;
    font-size: 14px;
    padding: 20px 0;
}

.login-button-area {
    display: flex;
    justify-content: center;
}

.login-join-box {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.login-join-button-area {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0;
}

.joinus-box {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .login-box {
        display: flex;
        flex-direction: column;
    }
    .login-box-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login-box-item-left {
        width: 100%;
    }

    .login-box-right {
        width: 80%;
    }
}
